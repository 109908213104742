<template>
  <div>
    <div class="container">
      <div class="row justify-content-between">

        <!-- From Address -->
        <div class="col-11 col-md-5 col-lg-4 pb-3 pb-md-4">
          <label for="startAddress" class="form-label">From</label>
          <input type="text" class="form-control" id="startAddress" v-model="startAddress" autocomplete="off" placeholder="Starting Address">
        </div>

        <div class="col-1 col-md-1 d-flex align-items-center justify-content-center">
          <button class="btn btn-light" @click="reverseAddresses">
            <i class="fas fa-exchange-alt"></i> <!-- or any suitable icon -->
          </button>
        </div>
        
        <!-- To Address -->
        <div class="col-12 col-md-6 col-lg-4 pb-3 pb-md-4">
          <label for="endAddress" class="form-label">To</label>
          <input type="text" class="form-control" id="endAddress" v-model="endAddress" placeholder="Ending Address">
        </div>

        <!-- Distance -->
        <div class="col-12 col-lg-2 pb-3 pb-md-4">
          <label for="distance" class="form-label">Distance</label>
          <input type="text" class="form-control" id="distance" :value="distance.toFixed(2) + ' miles'" disabled>
        </div>

        <div class="col-12 col-lg-1 pb-3 pb-md-4 d-flex align-items-end justify-content-end">
          <button class="btn btn-primary" @click="addJourney">Add</button>
        </div>

      </div>

      <div class="row justify-content-start">
        <!-- Miles Per Gallon -->
        <div class="col-6 col-md-3 pb-3 pb-md-4">
          <label for="mpg" class="form-label">Miles Per Gallon</label>
          <select class="form-select" id="mpg" v-model="mpg">
            <option v-for="n in 96" :key="n" :value="n + 4">{{ n + 4 }}</option>
          </select>
        </div>

        <!-- Gas Price -->
        <div class="col-6 col-md-3 pb-3 pb-md-4">
          <label for="gasPrice" class="form-label">Gas Price</label>
          <div class="input-group">
            <span class="input-group-text">$</span>
            <input type="text" class="form-control" id="gasPrice" v-model="formattedGasPrice" placeholder="Gas Price">
          </div>
        </div>

      </div>
      
      <!-- <div class="col-12 text-center pt-4">
        <div class="row justify-content-center">
          <div class="col-6 col-md-4 col-lg-2"><strong class="text-uppercase text-sm">One-Way</strong><h3 class="h1">${{ estimatedCost }}</h3></div>
          <div class="col-6 col-md-4 col-lg-2"><strong class="text-uppercase text-sm">Round-Trip</strong><h3 class="h1">${{ estimatedRoundTripCost }}</h3></div>
        </div>       
      </div> -->

    </div>

    <div class="container pt-2" v-if="journeyRecords.length">
      <div class="row">
        <div class="col-12">

          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>From / To</th>
                  <th>Miles</th>
                  <th>Cost</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) in journeyRecords" :key="index">
                  <td class="from-to"><span>{{ record.startAddress }} <strong>→</strong>&nbsp; </span><span>{{ record.endAddress }}</span></td>
                  <td>{{ record.distance }}</td>
                  <td>${{ record.cost }}</td>
                  <td>
                    <button class="btn btn-sm btn-red" @click="deleteJourney(index)">
                      <i class="fas fa-times"></i>
                      <span class="visually-hidden">Delete</span>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="1">Total</th>
                  <th>{{ totalMiles }}</th>
                  <th>${{ totalCost }}</th>
                  <th></th> <!-- Empty cell for the delete column -->
                </tr>
              </tfoot>
            </table>
          </div>
          
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global google */

export default {
  data() {
    return {
      startAddress: null,
      endAddress: null,
      mpg: 15,
      gasPrice: 3.92, // Default gas price
      distance: 0,
      journeyRecords: [],
    };
  },
  computed: {
    estimatedCost() {
      const cost = (this.distance / this.mpg) * this.gasPrice;
      return cost.toFixed(2);
    },
    estimatedRoundTripCost() {
      const roundTripDistance = this.distance * 2;
      const cost = (roundTripDistance / this.mpg) * this.gasPrice;
      return cost.toFixed(2);
    },

    formattedGasPrice: {
      get() {
        // Return the gasPrice as is
        return this.gasPrice;
      },
      set(value) {
        // Directly set the gasPrice to the input value
        this.gasPrice = value;
      }
    },

    totalMiles() {
      return this.journeyRecords.reduce((total, journey) => total + parseFloat(journey.distance), 0).toFixed(2);
    },

    totalCost() {
      return this.journeyRecords.reduce((total, journey) => total + parseFloat(journey.cost), 0).toFixed(2);
    },

  },
  mounted() {
    this.initAutocomplete();
    this.loadJourneysFromLocalStorage();
    this.loadJourneysFromSessionStorage();

    const savedMPG = localStorage.getItem('savedMPG');
    if (savedMPG) {
      this.mpg = Number(savedMPG);
    }
  },
  methods: {

    initAutocomplete() {
      const startAddressInput = document.getElementById('startAddress');
      const endAddressInput = document.getElementById('endAddress');
      this.startAutocomplete = new google.maps.places.Autocomplete(startAddressInput);
      this.endAutocomplete = new google.maps.places.Autocomplete(endAddressInput);

      this.startAutocomplete.addListener('place_changed', () => {
        const place = this.startAutocomplete.getPlace();
        if (place.geometry) {
          this.startAddress = place.formatted_address;
          // Set focus to the endAddress input
          endAddressInput.focus();
        }
      });

      this.endAutocomplete.addListener('place_changed', () => {
        const place = this.endAutocomplete.getPlace();
        if (place.geometry) {
          this.endAddress = place.formatted_address;
        }
      });
    },

    calculateDistance() {
      const service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [this.startAddress],
          destinations: [this.endAddress],
          travelMode: 'DRIVING',
        },
        (response, status) => {
          if (status === 'OK') {
            const results = response.rows[0].elements;
            const element = results[0];
            if (element.distance && element.distance.value) {
              const distanceInKm = element.distance.value / 1000; // distance in kilometers
              this.distance = distanceInKm * 0.621371; // convert to miles
              this.calculateCost();
            } else {
              console.error('Distance or value is undefined:', element);
            }
          } else {
            console.error('Error with Distance Matrix:', status);
          }
        }
      );
    },
    
    reverseAddresses() {
      const temp = this.startAddress;
      this.startAddress = this.endAddress;
      this.endAddress = temp;
    },

    calculateCost() {
      // Ensure gasPrice is a number and format it to two decimal places for calculation
      const formattedGasPrice = parseFloat(this.gasPrice).toFixed(2);
      this.estimatedCost = (this.distance / this.mpg) * formattedGasPrice;
    },

    addJourney() {
      // Check if both addresses are filled. If not, return and don't add the journey.
      if (!this.startAddress || !this.endAddress) {
        console.log('Both start and end addresses are required to add a journey.');
        return; // Exit the method early
      }

      // Proceed with adding a journey if both addresses are present
      const journeyRecord = {
        startAddress: this.startAddress,
        endAddress: this.endAddress,
        distance: this.distance.toFixed(2),
      };

      // Update all journey costs and add new journey
      this.journeyRecords.unshift(journeyRecord);
      this.updateAllJourneysCost(); // Update cost for all journeys
      this.saveJourneysToSessionStorage();
    },


    updateAllJourneysCost() {
      this.journeyRecords = this.journeyRecords.map(journey => {
        const distance = parseFloat(journey.distance);
        const cost = (distance / this.mpg) * this.gasPrice;
        return { ...journey, cost: cost.toFixed(2) }; // Update cost
      });
    },

    saveJourneysToSessionStorage() {
      sessionStorage.setItem('journeyRecords', JSON.stringify(this.journeyRecords));
    },

    loadJourneysFromSessionStorage() {
      const records = sessionStorage.getItem('journeyRecords');
      if (records) {
        this.journeyRecords = JSON.parse(records);
      }
    },

    saveJourneysToLocalStorage() {
      localStorage.setItem('journeyRecords', JSON.stringify(this.journeyRecords));
    },

    loadJourneysFromLocalStorage() {
      const records = localStorage.getItem('journeyRecords');
      if (records) {
        this.journeyRecords = JSON.parse(records);
      }
    },

    deleteJourney(index) {
      // Remove the journey from the array
      this.journeyRecords.splice(index, 1);

      // Save the updated array back to session storage, not local storage
      this.saveJourneysToSessionStorage();
    },

  },

  watch: {
    startAddress() {
      if (this.startAddress && this.endAddress) {
        this.calculateDistance();
      }
    },
    endAddress() {
      if (this.startAddress && this.endAddress) {
        this.calculateDistance();
      }
    },
    mpg(newVal) {
      localStorage.setItem('savedMPG', newVal);
      this.updateAllJourneysCost(); // Recalculate costs for all journeys
    },
    gasPrice() {
      this.updateAllJourneysCost(); // Recalculate costs for all journeys
    }
  }


};
</script>
