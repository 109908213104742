<template>
  <div id="app">
    <GasCalculator/>
  </div>
</template>

<script>
import GasCalculator from './components/GasCalculator.vue';

export default {
  name: 'App',
  components: {
    GasCalculator
  }
}
</script>